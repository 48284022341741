<template>
  <piepkaart :minLoadDataZoom="0">
    <template v-slot="{ bounds, minLoadDataZoom }">
      <piepkaart-json-layer
        :bounds="bounds"
        :minLoadDataZoom="minLoadDataZoom"
        :data="data"
        :onEachFeature="onEachFeature"
        :styleOptions="styleOptions"
      ></piepkaart-json-layer>
    </template>
  </piepkaart>
</template>
<script>
import Piepkaart from "@/components/Piepkaart.vue";
import PiepkaartJsonLayer from "@/components/PiepkaartJsonLayer.vue";
import geoData from "@/assets/data/stortplaatsen_zh.json";
import L from "leaflet";
export default {
  name: "Zuid-Holland",
  components: {
    Piepkaart,
    PiepkaartJsonLayer,
  },
  data() {
    return {
      maxBounds: L.latLngBounds([
        [51.67533205063522, 3.921806918642678],
        [52.30703305688419, 5.15097926293512],
      ]),
    };
  },
  computed: {
    data() {
      return geoData;
    },
  },
  methods: {
    styleOptions() {
      return {
        weight: 3,
        color: "#3B82F6",
        opacity: 1,
        fillColor: "#3B82F6",
        fillOpacity: 0.8,
      };
    },
    onEachFeature(feature, layer) {
      if (feature.properties) {
        layer.bindPopup(
          `
            <div>
              <div style="font-weight: 600">${feature.properties.Locatienaam}</div>
              <div>Plaats: ${feature.properties.PLAATSNAAM}</div>
              <div>Huidig gebruik: ${feature.properties.Huidig_gebruik}</div>
              <div>Sanering uitgevoerd: ${feature.properties.Sanering_uitgevoerd}</div>
            </div>
            `,
          {
            closeButton: false,
            autoPan: false,
          }
        );
      }
    },
  },
  created() {
    this.$store.commit("center", this.maxBounds.getCenter());
    this.$store.commit("zoom", 10);
  },
};
</script>
